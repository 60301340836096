export default {
  props: {},
  data() {
    return {
      imgHeight: 'auto',
      dataArr: [],
      left: 0,
      active: 0,
      lang: this.$store.state.lang,
      currentSrc: 0
    };
  },
  watch: {
    '$store.state.lang': {
      deep: true,
      immediate: true,
      handler(val) {
        this.lang = val;
        this.watchData().then(() => {
          this.currentSrc = this.dataArr[this.active].src;
          console.log(this.cu);
        });
      }
    }
  },
  computed: {},
  components: {},
  created() {
    // console.log('$route.query.value',this.$route.query.value)
  },
  mounted() {
    // this.setHeight()
    let val = this.$route.query.value || 0;
    this.active = Number(val);
    this.watchData().then(() => {
      this.currentSrc = this.dataArr[this.active].src;
    });
  },
  beforeRouteLeave() {},
  methods: {
    watchData() {
      return new Promise(resolve => {
        if (this.lang === 1) {
          this.dataArr = [{
            src: require('./../../assets/images/four/Q12-en.png')
          }, {
            src: require('./../../assets/images/four/X12PRO-en.png')
          }, {
            src: require('./../../assets/images/four/X12-en.png')
          }, {
            src: require('./../../assets/images/four/E12-en.png')
          }, {
            src: require('./../../assets/images/four/G12-en.png')
          }, {
            src: require('./../../assets/images/four/E16-en.png')
          }, {
            src: require('./../../assets/images/four/E18-en.png')
          }, {
            src: require('./../../assets/images/four/JETPODS-en.png')
          }, {
            src: require('./../../assets/images/four/M200-en.png')
          }];
        } else if (this.lang === 2) {
          this.dataArr = [{
            src: require('./../../assets/images/four/Q12.png')
          }, {
            src: require('./../../assets/images/four/X12PRO.png')
          }, {
            src: require('./../../assets/images/four/X12.png')
          }, {
            src: require('./../../assets/images/four/E12.png')
          }, {
            src: require('./../../assets/images/four/G12.png')
          }, {
            src: require('./../../assets/images/four/E16.png')
          }, {
            src: require('./../../assets/images/four/E18.png')
          }, {
            src: require('./../../assets/images/four/JETPODS.png')
          }, {
            src: require('./../../assets/images/four/M200.png')
          }];
        }
        resolve();
      });
    },
    setSroll(distance) {
      // console.log('distance',distance)
      const screenWidth = window.screen.width; //获取屏幕的宽度
      const container = document.querySelector('.com-contentTwo-con');
      const img = document.querySelector('.com-contentTwo-vanImage');
      const imgWidthPx = window.getComputedStyle(img).width;
      const duration = 500; // 滚动的持续时间（毫秒）

      const startTime = performance.now();
      const startScroll = parseFloat(container.style.left.split('px')[0]);
      const endScroll = startScroll + distance;
      // console.log('startScroll---',startScroll)
      // console.log('endScroll---',endScroll)
      // console.log('imgWidth---',imgWidth)

      window.requestAnimationFrame(scroll);
      function scroll(timestamp) {
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        let x = Math.abs(parseInt(endScroll)) + screenWidth;
        // let containerLeft = parseFloat(container.style.left.split('px')[0])
        let imgWidth = parseFloat(imgWidthPx.split('px')[0]); //图片的总宽度
        if (x >= imgWidth) {
          let _endScroll = screenWidth - imgWidth;
          const scrollTo = startScroll + progress * (_endScroll - startScroll);
          container.style.left = scrollTo + 'px';
        } else {
          let _endScroll = endScroll < 0 ? endScroll : 0;
          const scrollTo = startScroll + progress * (_endScroll - startScroll);
          container.style.left = scrollTo + 'px';
          // if(_endScroll==0){

          // }
        }
        // console.log('progress---',progress)
        // console.log('scrollTo---',scrollTo)

        if (progress < 1) {
          window.requestAnimationFrame(scroll);
        }
      }
    }
  },
  scroll() {}
};