
export const PRUDUCTS = {
  1: [
    {
      name: 'Q12',
      value: 10,
      src: require('./../../assets/images/one/11.png')
    },
    {
      name: 'X12 PRO',
      value: 0,
      src: require('./../../assets/images/one/1.png')
    },
    {
      name: 'X12 ULTRA',
      value: 1,
      src: require('./../../assets/images/one/2.png')
    },
    // {
    //   name: 'X12 ELITE',
    //   value: 2,
    //   src: require('./../../assets/images/one/3.png')
    // },
    {
      name: 'E12 ULTRA',
      value: 3,
      src: require('./../../assets/images/one/4.png')
    },
    // {
    //   name: 'E12 ELITE',
    //   value: 4,
    //   src: require('./../../assets/images/one/5.png')
    // },
    {
      name: 'G12 ELITE',
      value: 5,
      src: require('./../../assets/images/one/6.png')
    },
    {
      name: 'E16',
      value: 6,
      src: require('./../../assets/images/one/7.png')
    },
    {
      name: 'E18',
      value: 7,
      src: require('./../../assets/images/one/8.png')
    },
    {
      name: 'JETPODS',
      value: 8,
      src: require('./../../assets/images/one/9.png')
    },
    {
      name: 'M200',
      value: 9,
      text: '关闭',
      src: require('./../../assets/images/one/10.png')
    },
  ],
  2: [
    {
      name: 'Q12',
      value: 10,
      src: require('./../../assets/images/one/011.png')
    },
    {
      name: 'X12 PRO',
      value: 0,
      text: '关闭',
      src: require('./../../assets/images/one/01.png')
    },
    {
      name: 'X12 ULTRA',
      value: 1,
      text: '关闭',
      src: require('./../../assets/images/one/02.png')
    },
    // {
    //   name: 'X12 ELITE',
    //   value: 2,
    //   src: require('./../../assets/images/one/3.png')
    // },
    {
      name: 'E12 ULTRA',
      value: 3,
      text: '关闭',
      src: require('./../../assets/images/one/04.png')
    },
    // {
    //   name: 'E12 ELITE',
    //   value: 4,
    //   src: require('./../../assets/images/one/5.png')
    // },
    {
      name: 'G12 ELITE',
      value: 5,
      text: '关闭',
      src: require('./../../assets/images/one/06.png')
    },
    {
      name: 'E16',
      value: 6,
      text: '关闭',
      src: require('./../../assets/images/one/07.png')
    },
    {
      name: 'E18',
      value: 7,
      text: '关闭',
      src: require('./../../assets/images/one/08.png')
    },
    {
      name: 'JETPODS',
      value: 8,
      text: '关闭',
      src: require('./../../assets/images/one/09.png')
    },
    {
      name: 'M200',
      value: 9,
      text: '关闭',
      src: require('./../../assets/images/one/010.png')
    },
  ]
}

export const MENULANG = {
  1: [
    {
      title: 'HOME',
      name: 'detail',
      value: 0,
      list: []
    },
    {
      title: 'PRODUCT',
      name: 'product',
      value: 1,
      list: PRUDUCTS[1]
    },
    {
      title: 'ABOUTUS',
      name: 'about',
      value: 2,
      list: []
    },
    {
      title: 'SUPPORT',
      name: 'support',
      value: 3,
      list: []
    }  
  ],
  2: [
    {
      title: '主页',
      name: 'detail',
      value: 0,
      list: []
    },
    {
      title: '产品',
      name: 'product',
      value: 1,
      list: PRUDUCTS[2]
    },
    {
      title: '关于我们',
      name: 'about',
      value: 2,
      list: []
    },
    {
      title: '支持',
      name: 'support',
      value: 3,
      list: []
    }  
  ]
}

export const PRODUCT_LONGIMG = {
  1:{
    'Q12':{src: require('./../../assets/images/four/Q12.png'),},
    'X12 PRO':{src: require('./../../assets/images/four/X12PRO.png'),},
    'X12':{src: require('./../../assets/images/four/X12.png'),},
    'E16':{src: require('./../../assets/images/four/E16.png'),},
    'E18':{src: require('./../../assets/images/four/E18.png'),},
    'G12':{src: require('./../../assets/images/four/G12.png'),},
    'JETPODS':{src: require('./../../assets/images/four/E18.png'),},
    'M200':{src: require('./../../assets/images/four/E18.png'),},
  },
  2:{
    'Q12':{src: require('./../../assets/images/four/Q12.png'),},
    'X12 PRO':{src: require('./../../assets/images/four/X12PRO.png'),},
    'X12':{src: require('./../../assets/images/four/X12.png'),},
    'E16':{src: require('./../../assets/images/four/E16.png'),},
    'E18':{src: require('./../../assets/images/four/E18.png'),},
    'G12':{src: require('./../../assets/images/four/G12.png'),},
    'JETPODS':{src: require('./../../assets/images/four/E18.png'),},
    'M200':{src: require('./../../assets/images/four/E18.png'),},
  }
}

export const PRODUCT_DETAIL = {
  1: {
    'Q12': [
      {
        title: 'BLANK AND WHITE STRING',
        src: require('./../../assets/images/two/Q12/2.png'),
        cover: require('./../../assets/images/two/Q12/cover/1.png'),
        footer: require('./../../assets/images/two/Q12/footer-en.png'),
        name: 'Black And White String',
        summary: 'Open Wearable Stereo',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/Q12/2/2/cover/pro.png'),
              require('@/assets/images/three/Q12/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/2/front/middle-en.png'),
              require('@/assets/images/three/Q12/2/2/front/top.png'),
              require('@/assets/images/three/Q12/2/2/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/2/base/bg-en.png'),
              require('@/assets/images/three/Q12/2/2/base/middle.png'),
              require('@/assets/images/three/Q12/2/2/base/top.png'),
              require('@/assets/images/three/Q12/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'BLAST SILVER',
        src: require('./../../assets/images/two/Q12/3.png'),
        cover: require('./../../assets/images/two/Q12/cover/2.png'),
        footer: require('./../../assets/images/two/Q12/footer-en.png'),
        name: 'BLAST SILVER',
        summary: 'Open Wearable Stereo',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/Q12/2/3/cover/pro.png'),
              require('@/assets/images/three/Q12/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/3/front/middle-en.png'),
              require('@/assets/images/three/Q12/2/3/front/top.png'),
              require('@/assets/images/three/Q12/2/3/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/3/base/bg-en.png'),
              require('@/assets/images/three/Q12/2/3/base/middle.png'),
              require('@/assets/images/three/Q12/2/3/base/top.png'),
              require('@/assets/images/three/Q12/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'SPROUTING GREEN',
        src: require('./../../assets/images/two/Q12/4.png'),
        cover: require('./../../assets/images/two/Q12/cover/3.png'),
        footer: require('./../../assets/images/two/Q12/footer-en.png'),
        name: 'SPROUTING GREEN',
        summary: 'Open Wearable Stereo',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/Q12/2/4/cover/pro.png'),
              require('@/assets/images/three/Q12/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/4/front/middle-en.png'),
              require('@/assets/images/three/Q12/2/4/front/top.png'),
              require('@/assets/images/three/Q12/2/4/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/4/base/bg-en.png'),
              require('@/assets/images/three/Q12/2/4/base/middle.png'),
              require('@/assets/images/three/Q12/2/4/base/top.png'),
              require('@/assets/images/three/Q12/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'ELECTRIC PURPLE',
        src: require('./../../assets/images/two/Q12/1.png'),
        cover: require('./../../assets/images/two/Q12/cover/4.png'),
        footer: require('./../../assets/images/two/Q12/footer-en.png'),
        name: 'ELECTRIC PURPLE',
        summary: 'Open Wearable Stereo',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/Q12/2/1/cover/pro.png'),
              require('@/assets/images/three/Q12/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/1/front/middle-en.png'),
              require('@/assets/images/three/Q12/2/1/front/top.png'),
              require('@/assets/images/three/Q12/2/1/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/1/base/bg-en.png'),
              require('@/assets/images/three/Q12/2/1/base/middle.png'),
              require('@/assets/images/three/Q12/2/1/base/top.png'),
              require('@/assets/images/three/Q12/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'INK BLACK',
        src: require('./../../assets/images/two/Q12/5.png'),
        cover: require('./../../assets/images/two/Q12/cover/5.png'),
        footer: require('./../../assets/images/two/Q12/footer-en.png'),
        name: 'INK BLACK',
        summary: 'Open Wearable Stereo',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/Q12/2/5/cover/pro.png'),
              require('@/assets/images/three/Q12/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/5/front/middle-en.png'),
              require('@/assets/images/three/Q12/2/5/front/top.png'),
              require('@/assets/images/three/Q12/2/5/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/5/base/bg-en.png'),
              require('@/assets/images/three/Q12/2/5/base/middle.png'),
              require('@/assets/images/three/Q12/2/5/base/top.png'),
              require('@/assets/images/three/Q12/2/5/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'VIBRANT MAGENTA',
        src: require('./../../assets/images/two/Q12/8.png'),
        cover: require('./../../assets/images/two/Q12/cover/6.png'),
        footer: require('./../../assets/images/two/Q12/footer-en.png'),
        name: 'VIBRANT MAGENTA',
        summary: 'Open Wearable Stereo',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/Q12/2/8/cover/pro.png'),
              require('@/assets/images/three/Q12/2/8/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/8/front/middle-en.png'),
              require('@/assets/images/three/Q12/2/8/front/top.png'),
              require('@/assets/images/three/Q12/2/8/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/8/base/bg-en.png'),
              require('@/assets/images/three/Q12/2/8/base/middle.png'),
              require('@/assets/images/three/Q12/2/8/base/top.png'),
              require('@/assets/images/three/Q12/2/8/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'SKY BLUE',
        src: require('./../../assets/images/two/Q12/6.png'),
        cover: require('./../../assets/images/two/Q12/cover/7.png'),
        footer: require('./../../assets/images/two/Q12/footer-en.png'),
        name: 'SKY BLUE',
        summary: 'Open Wearable Stereo',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/Q12/2/6/cover/pro.png'),
              require('@/assets/images/three/Q12/2/6/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/6/front/middle-en.png'),
              require('@/assets/images/three/Q12/2/6/front/top.png'),
              require('@/assets/images/three/Q12/2/6/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/6/base/bg-en.png'),
              require('@/assets/images/three/Q12/2/6/base/middle.png'),
              require('@/assets/images/three/Q12/2/6/base/top.png'),
              require('@/assets/images/three/Q12/2/6/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'CLOUD WHITE',
        src: require('./../../assets/images/two/Q12/7.png'),
        cover: require('./../../assets/images/two/Q12/cover/8.png'),
        footer: require('./../../assets/images/two/Q12/footer-en.png'),
        name: 'CLOUD WHITE',
        summary: 'Open Wearable Stereo',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/Q12/2/7/cover/pro.png'),
              require('@/assets/images/three/Q12/2/7/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/7/front/middle-en.png'),
              require('@/assets/images/three/Q12/2/7/front/top.png'),
              require('@/assets/images/three/Q12/2/7/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/7/base/bg-en.png'),
              require('@/assets/images/three/Q12/2/7/base/middle.png'),
              require('@/assets/images/three/Q12/2/7/base/top.png'),
              require('@/assets/images/three/Q12/2/7/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'X12 PRO': [
      {
        title: 'FLAME',
        src: require('./../../assets/images/two/X12PRO/10.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/10.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'FLAME',
        summary: 'While Enjoying Loneliness, You Can Also Burn Yourself.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/10/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/10/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/10/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/10/front/top.png'),
              require('@/assets/images/three/X12PRO/2/10/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/10/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/10/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/10/base/top.png'),
              require('@/assets/images/three/X12PRO/2/10/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'FANTASY',
        src: require('./../../assets/images/two/X12PRO/9.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/9.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'FANTASY',
        summary: 'New Peak Of Beauty All In Your Fantasy.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/9/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/9/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/9/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/9/front/top.png'),
              require('@/assets/images/three/X12PRO/2/9/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/9/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/9/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/9/base/top.png'),
              require('@/assets/images/three/X12PRO/2/9/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'DANCER',
        src: require('./../../assets/images/two/X12PRO/8.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/8.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'DANCER',
        summary: 'Stay Up All Night Dancing With Your Ghost.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/8/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/8/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/8/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/8/front/top.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/8/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/8/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/8/base/top.png'),
              require('@/assets/images/three/X12PRO/2/8/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'BLOOMING',
        src: require('./../../assets/images/two/X12PRO/7.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/7.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'BLOOMING',
        summary: 'New Peak Of Beauty Wander In The Blooming Kingdom.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/7/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/7/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/7/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/7/front/top.png'),
              require('@/assets/images/three/X12PRO/2/7/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/7/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/7/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/7/base/top.png'),
              require('@/assets/images/three/X12PRO/2/7/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'HERE WITE YOU',
        src: require('./../../assets/images/two/X12PRO/6.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/6.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'HERE WITE YOU',
        summary: 'New Peak Of Beauty Bright Star Here With You.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/6/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/6/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/6/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/6/front/top.png'),
              require('@/assets/images/three/X12PRO/2/6/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/6/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/6/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/6/base/top.png'),
              require('@/assets/images/three/X12PRO/2/6/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'ICE SOUL',
        src: require('./../../assets/images/two/X12PRO/5.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/5.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'ICE SOUL',
        summary: 'Cool As Ice Soul Calm Down Yourself.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/5/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/5/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/5/front/top.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/5/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/5/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/5/base/top.png'),
              require('@/assets/images/three/X12PRO/2/5/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'BLACK',
        src: require('./../../assets/images/two/X12PRO/4.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/4.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'BLACK',
        summary: 'Coming Music Bright Your Dark Night.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/4/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/4/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/4/front/top.png'),
              require('@/assets/images/three/X12PRO/2/4/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/4/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/4/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/4/base/top.png'),
              require('@/assets/images/three/X12PRO/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'WHITE',
        src: require('./../../assets/images/two/X12PRO/3.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/3.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'WHITE',
        summary: 'Looking For Your Own Snow White.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/3/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/3/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/3/front/top.png'),
              require('@/assets/images/three/X12PRO/2/3/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/3/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/3/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/3/base/top.png'),
              require('@/assets/images/three/X12PRO/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'PINK',
        src: require('./../../assets/images/two/X12PRO/2.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/2.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'PINK',
        summary: "Pink powder arouses love and fills a girl's heart.",
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/2/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/2/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/2/front/top.png'),
              require('@/assets/images/three/X12PRO/2/2/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/2/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/2/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/2/base/top.png'),
              require('@/assets/images/three/X12PRO/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'GREEN',
        src: require('./../../assets/images/two/X12PRO/1.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/1.png'),
        footer: require('./../../assets/images/two/X12PRO/footer-en.png'),
        name: 'GREEN',
        summary: 'Fresh Summer With Light Green.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12PRO/2/1/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/1/front/middle-en.png'),
              require('@/assets/images/three/X12PRO/2/1/front/top.png'),
              require('@/assets/images/three/X12PRO/2/1/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/1/base/bg-en.png'),
              require('@/assets/images/three/X12PRO/2/1/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/1/base/top.png'),
              require('@/assets/images/three/X12PRO/2/1/base/bottom.png'),
            ],
          }
        ]
      },
    ],
    'X12 ULTRA': [
      {
        title: 'SNOW WHITE',
        src: require('./../../assets/images/two/X12ULTRA/1.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/1.png'),
        name: 'SNOW WHITE',
        summary: 'Start With Snow.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12ULTRA/2/1/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/1/front/middle-en.png'),
              require('@/assets/images/three/X12ULTRA/2/1/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/1/front/bottom.png')
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/1/base/bg-en.png'),
              require('@/assets/images/three/X12ULTRA/2/1/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/1/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'YAN YANSHI',
        src: require('./../../assets/images/two/X12ULTRA/4.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/4.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer-en.png'),
        name: 'YAN YANSHI',
        summary: 'Fragrance Comes From Cherry, Beauty Needs Your Care.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12ULTRA/2/4/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/4/front/middle-en.png'),
              require('@/assets/images/three/X12ULTRA/2/4/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/4/front/bottom.png')
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/4/base/bg-en.png'),
              require('@/assets/images/three/X12ULTRA/2/4/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/4/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'STAR CLOUD',
        src: require('./../../assets/images/two/X12ULTRA/5.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/5.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer-en.png'),
        name: 'STAR CLOUD',
        summary: 'Fresh Summer With Light Green.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12ULTRA/2/5/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/5/front/middle-en.png'),
              require('@/assets/images/three/X12ULTRA/2/5/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/5/front/bottom.png')
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/5/base/bg-en.png'),
              require('@/assets/images/three/X12ULTRA/2/5/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/5/base/top.png'),
            ],
          }
        ]
      },
      {
        title: 'GALALINE',
        src: require('./../../assets/images/two/X12ULTRA/6.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/6.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer-en.png'),
        name: 'GALALINE',
        summary: 'Explore The Music Universe With Canaan Stone.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12ULTRA/2/6/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/6/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/6/front/middle-en.png'),
              require('@/assets/images/three/X12ULTRA/2/6/front/top.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/6/base/bg-en.png'),
              require('@/assets/images/three/X12ULTRA/2/6/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/6/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/6/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'ADVANCED STONE',
        src: require('./../../assets/images/two/X12ULTRA/3.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/3.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer-en.png'),
        name: 'ADVANCED STONE',
        summary: 'Be With Nature, Proud Of Advance.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12ULTRA/2/3/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/3/front/middle-en.png'),
              require('@/assets/images/three/X12ULTRA/2/3/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/3/front/bottom.png')
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/3/base/bg-en.png'),
              require('@/assets/images/three/X12ULTRA/2/3/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/3/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'DREAM STONE',
        src: require('./../../assets/images/two/X12ULTRA/2.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/2.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer-en.png'),
        name: 'DREAM STONE',
        summary: 'All Dreams Will Come True Once You Pursue.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/X12ULTRA/2/2/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/2/front/middle-en.png'),
              require('@/assets/images/three/X12ULTRA/2/2/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/2/front/bottom.png')
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/2/base/bg-en.png'),
              require('@/assets/images/three/X12ULTRA/2/2/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/2/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/2/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'E12 ULTRA': [
      {
        title: 'DREAM STONE',
        src: require('./../../assets/images/two/E12ULTRA/10.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/10.png'),
        name: 'DREAM STONE',
        summary: 'All Dreams Will Come True Once You Pursue.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/10/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/10/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/10/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/10/front/top.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/10/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/10/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/10/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/10/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'ADVANCED STONE',
        src: require('./../../assets/images/two/E12ULTRA/9.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/9.png'),
        name: 'ADVANCED STONE',
        summary: 'Be With Nature, Proud Of Advance.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/9/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/9/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/9/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/9/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/9/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/9/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/9/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/9/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/9/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'GALALINE',
        src: require('./../../assets/images/two/E12ULTRA/8.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/8.png'),
        name: 'GALALINE',
        summary: 'Explore The Music Universe With Canaan Stone.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/8/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/8/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/8/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/8/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/8/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/8/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/8/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/8/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/8/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'STAR CLOUD',
        src: require('./../../assets/images/two/E12ULTRA/7.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/7.png'),
        name: 'STAR CLOUD',
        summary: 'Star Cloud Stone In Hand, The Music World Have It.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/7/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/7/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/7/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/7/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/7/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/7/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/7/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/7/base/top.png'),
            ],
          }
        ]
      },
      {
        title: 'YAN YANSHI',
        src: require('./../../assets/images/two/E12ULTRA/6.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/6.png'),
        name: 'YAN YANSHI',
        summary: 'Fragrance Comes From Cherry, Beauty Needs Your Care.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/6/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/6/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/6/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/6/front/top.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/6/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/6/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/6/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/6/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'GLITTER DARK',
        src: require('./../../assets/images/two/E12ULTRA/5.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/5.png'),
        name: 'GLITTER DARK',
        summary: 'Flow The Glitter Music Into Your Living.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/5/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/5/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/5/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/5/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/5/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/5/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/5/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/5/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'NEON RED',
        src: require('./../../assets/images/two/E12ULTRA/4.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/4.png'),
        name: 'NEON RED',
        summary: 'Enjoy The Music Beptism With The Neon Red.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/4/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/4/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/4/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/4/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/4/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/4/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/4/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'GALAXY BLUE',
        src: require('./../../assets/images/two/E12ULTRA/3.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/3.png'),
        name: 'GALAXY BLUE',
        summary: 'Dreamy Galaxy Where The Joy Begins.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/3/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/3/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/3/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/3/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/3/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/3/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/3/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'AURORA GREEN',
        src: require('./../../assets/images/two/E12ULTRA/2.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/1.png'),
        name: 'AURORA GREEN',
        summary: 'Enjoy the music beptism with the aurora green.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/2/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/2/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/2/front/top.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/2/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/2/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/2/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'SNOW WHITE',
        src: require('./../../assets/images/two/E12ULTRA/1.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer-en.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/1.png'),
        name: 'SNOW WHITE',
        summary: 'Start With Snow.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E12ULTRA/2/1/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/1/front/middle-en.png'),
              require('@/assets/images/three/E12ULTRA/2/1/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/1/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/1/base/bg-en.png'),
              require('@/assets/images/three/E12ULTRA/2/1/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/1/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/1/base/bottom.png'),
            ],
          }
        ]
      },
    ],
    'G12 ELITE': [
      {
        title: 'CHALLENGE',
        src: require('./../../assets/images/two/G12ELITE/1.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer-en.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/1.png'),
        name: 'CHALLENGE',
        summary: 'Ingenuity Is The Voice Of The Creator With His Life.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/G12ELITE/2/1/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/1/front/middle-en.png'),
              require('@/assets/images/three/G12ELITE/2/1/front/top.png'),
              require('@/assets/images/three/G12ELITE/2/1/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/1/base/bg-en.png'),
              require('@/assets/images/three/G12ELITE/2/1/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/1/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'TRUTH',
        src: require('./../../assets/images/two/G12ELITE/2.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer-en.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/2.png'),
        name: 'TRUTH',
        summary: 'We Given, We Striven, Turns Every Note Harmony Driven.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/G12ELITE/2/2/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/2/front/middle-en.png'),
              require('@/assets/images/three/G12ELITE/2/2/front/top.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/2/base/bg-en.png'),
              require('@/assets/images/three/G12ELITE/2/2/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/2/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'UNITY',
        src: require('./../../assets/images/two/G12ELITE/3.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer-en.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/3.png'),
        name: 'UNITY',
        summary: 'Sabbat! Hearing The Voice Of The Universe.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/G12ELITE/2/3/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/3/front/middle-en.png'),
              require('@/assets/images/three/G12ELITE/2/3/front/top.png'),
              require('@/assets/images/three/G12ELITE/2/3/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/3/base/bg-en.png'),
              require('@/assets/images/three/G12ELITE/2/3/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/3/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'DEFEND',
        src: require('./../../assets/images/two/G12ELITE/4.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer-en.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/4.png'),
        name: 'DEFEND',
        summary: 'Dare To Release, Dare To Strive.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/G12ELITE/2/4/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/4/front/middle-en.png'),
              require('@/assets/images/three/G12ELITE/2/4/front/top.png'),
              require('@/assets/images/three/G12ELITE/2/4/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/4/base/bg-en.png'),
              require('@/assets/images/three/G12ELITE/2/4/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/4/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'HARMONY',
        src: require('./../../assets/images/two/G12ELITE/5.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer-en.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/5.png'),
        name: 'HARMONY',
        summary: 'Fight For Youth, Fight For Dreams.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/G12ELITE/2/5/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/5/front/middle-en.png'),
              require('@/assets/images/three/G12ELITE/2/5/front/top.png'),
              require('@/assets/images/three/G12ELITE/2/5/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/5/base/bg-en.png'),
              require('@/assets/images/three/G12ELITE/2/5/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/5/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/5/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'E16': [
      {
        title: 'NEON',
        src: require('./../../assets/images/two/E16/1.png'),
        footer: require('./../../assets/images/two/E16/footer-en.png'),
        cover: require('./../../assets/images/two/E16/cover/1.png'),
        name: 'NEON',
        summary: 'Dare To Release, Dare To Strive.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E16/2/1/cover/pro.png'),
              require('@/assets/images/three/E16/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E16/2/1/front/middle-en.png'),
              require('@/assets/images/three/E16/2/1/front/top.png'),
              require('@/assets/images/three/E16/2/1/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E16/2/1/base/bg-en.png'),
              require('@/assets/images/three/E16/2/1/base/middle.png'),
              require('@/assets/images/three/E16/2/1/base/top.png'),
              require('@/assets/images/three/E16/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'AURORA',
        src: require('./../../assets/images/two/E16/2.png'),
        footer: require('./../../assets/images/two/E16/footer-en.png'),
        cover: require('./../../assets/images/two/E16/cover/2.png'),
        name: 'AURORA',
        summary: 'Enjoy The Music Beptism With The Aurora Green.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E16/2/2/cover/pro.png'),
              require('@/assets/images/three/E16/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E16/2/2/front/middle-en.png'),
              require('@/assets/images/three/E16/2/2/front/top.png'),
              require('@/assets/images/three/E16/2/2/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E16/2/2/base/bg-en.png'),
              require('@/assets/images/three/E16/2/2/base/middle.png'),
              require('@/assets/images/three/E16/2/2/base/top.png'),
              require('@/assets/images/three/E16/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'MYSTIC',
        src: require('./../../assets/images/two/E16/3.png'),
        footer: require('./../../assets/images/two/E16/footer-en.png'),
        cover: require('./../../assets/images/two/E16/cover/3.png'),
        name: 'MYSTIC',
        summary: 'Fight For Youth, Fight For Dreams.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E16/2/3/cover/pro.png'),
              require('@/assets/images/three/E16/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E16/2/3/front/middle-en.png'),
              require('@/assets/images/three/E16/2/3/front/top.png'),
              require('@/assets/images/three/E16/2/3/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E16/2/3/base/bg-en.png'),
              require('@/assets/images/three/E16/2/3/base/middle.png'),
              require('@/assets/images/three/E16/2/3/base/top.png'),
              require('@/assets/images/three/E16/2/3/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'E18': [
      {
        title: 'MOONLIGHT SILVER',
        src: require('./../../assets/images/two/E18/1.png'),
        footer: require('./../../assets/images/two/E18/footer-en.png'),
        cover: require('./../../assets/images/two/E18/cover/1.png'),
        name: 'MOONLIGHT SILVER',
        summary: 'Dare To Live, Dare To Awake.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E18/2/1/cover/pro.png'),
              require('@/assets/images/three/E18/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/1/front/middle-en.png'),
              require('@/assets/images/three/E18/2/1/front/top.png'),
              require('@/assets/images/three/E18/2/1/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/1/base/bg-en.png'),
              require('@/assets/images/three/E18/2/1/base/middle.png'),
              require('@/assets/images/three/E18/2/1/base/top.png'),
              require('@/assets/images/three/E18/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'DARK BLACK',
        src: require('./../../assets/images/two/E18/2.png'),
        footer: require('./../../assets/images/two/E18/footer-en.png'),
        cover: require('./../../assets/images/two/E18/cover/2.png'),
        name: 'DARK BLACK',
        summary: 'Within My Music, My Soul Flames.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E18/2/2/cover/pro.png'),
              require('@/assets/images/three/E18/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/2/front/middle-en.png'),
              require('@/assets/images/three/E18/2/2/front/top.png'),
              require('@/assets/images/three/E18/2/2/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/2/base/bg-en.png'),
              require('@/assets/images/three/E18/2/2/base/middle.png'),
              require('@/assets/images/three/E18/2/2/base/top.png'),
              require('@/assets/images/three/E18/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'FLUORESCEIN RED',
        src: require('./../../assets/images/two/E18/3.png'),
        footer: require('./../../assets/images/two/E18/footer-en.png'),
        cover: require('./../../assets/images/two/E18/cover/3.png'),
        name: 'FLUORESCEIN RED',
        summary: 'Superior Audio Bestowed By Awesome Tune.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E18/2/3/cover/pro.png'),
              require('@/assets/images/three/E18/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/3/front/middle-en.png'),
              require('@/assets/images/three/E18/2/3/front/top.png'),
              require('@/assets/images/three/E18/2/3/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/3/base/bg-en.png'),
              require('@/assets/images/three/E18/2/3/base/middle.png'),
              require('@/assets/images/three/E18/2/3/base/top.png'),
              require('@/assets/images/three/E18/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'AURORA GREEN',
        src: require('./../../assets/images/two/E18/4.png'),
        footer: require('./../../assets/images/two/E18/footer-en.png'),
        cover: require('./../../assets/images/two/E18/cover/4.png'),
        name: 'AURORA GREEN',
        summary: 'We Could Be The Whole World.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E18/2/4/cover/pro.png'),
              require('@/assets/images/three/E18/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/4/front/middle-en.png'),
              require('@/assets/images/three/E18/2/4/front/top.png'),
              require('@/assets/images/three/E18/2/4/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/4/base/bg-en.png'),
              require('@/assets/images/three/E18/2/4/base/middle.png'),
              require('@/assets/images/three/E18/2/4/base/top.png'),
              require('@/assets/images/three/E18/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'SPIRIT BLUE',
        src: require('./../../assets/images/two/E18/5.png'),
        footer: require('./../../assets/images/two/E18/footer-en.png'),
        cover: require('./../../assets/images/two/E18/cover/5.png'),
        name: 'SPIRIT BLUE',
        summary: 'Ingenuity Is The Voice Of The Creator With His Life.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/E18/2/5/cover/pro.png'),
              require('@/assets/images/three/E18/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/5/front/middle-en.png'),
              require('@/assets/images/three/E18/2/5/front/top.png'),
              require('@/assets/images/three/E18/2/5/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/5/base/bg-en.png'),
              require('@/assets/images/three/E18/2/5/base/middle.png'),
              require('@/assets/images/three/E18/2/5/base/top.png'),
              require('@/assets/images/three/E18/2/5/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'JETPODS': [
      {
        title: 'IVORY WHITE',
        src: require('./../../assets/images/two/JETPODS/1.png'),
        footer: require('./../../assets/images/two/JETPODS/footer-en.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/1.png'),
        name: 'IVORY WHITE',
        summary: 'Superior Audio Bestowed By Awesome Tune.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/JETPODS/2/1/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/1/cover/bg.png')
            ],
            tagText:'OVERVIEW'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/1/front/middle-en.png'),
              require('@/assets/images/three/JETPODS/2/1/front/top.png'),
              require('@/assets/images/three/JETPODS/2/1/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/1/base/bg-en.png'),
              require('@/assets/images/three/JETPODS/2/1/base/middle.png'),
              require('@/assets/images/three/JETPODS/2/1/base/top.png'),
            ],
          }
        ]
      },
      {
        title: 'BAMBOO GREEN',
        src: require('./../../assets/images/two/JETPODS/2.png'),
        footer: require('./../../assets/images/two/JETPODS/footer-en.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/2.png'),
        name: 'BAMBOO GREEN',
        summary: 'Fight For Youth, Fight For Dreams.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/JETPODS/2/2/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/2/cover/bg.png')
            ],
            tagText:'OVERVIEW'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/2/front/middle-en.png'),
              require('@/assets/images/three/JETPODS/2/2/front/top.png'),
              require('@/assets/images/three/JETPODS/2/2/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/2/base/bg-en.png'),
              require('@/assets/images/three/JETPODS/2/2/base/middle.png'),
              require('@/assets/images/three/JETPODS/2/2/base/top.png'),
            ],
          }
        ]
      },
      {
        title: 'CORAL RED',
        src: require('./../../assets/images/two/JETPODS/3.png'),
        footer: require('./../../assets/images/two/JETPODS/footer-en.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/3.png'),
        name: 'CORAL RED',
        summary: 'We Could Be The Whole World.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/JETPODS/2/3/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/3/cover/bg.png')
            ],
            tagText:'OVERVIEW'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/3/front/middle-en.png'),
              require('@/assets/images/three/JETPODS/2/3/front/top.png'),
              require('@/assets/images/three/JETPODS/2/3/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/3/base/bg-en.png'),
              require('@/assets/images/three/JETPODS/2/3/base/middle.png'),
              require('@/assets/images/three/JETPODS/2/3/base/top.png'),
            ],
          }
        ]
      },
      {
        title: 'OCEAN BLUE',
        src: require('./../../assets/images/two/JETPODS/4.png'),
        footer: require('./../../assets/images/two/JETPODS/footer-en.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/4.png'),
        name: 'OCEAN BLUE',
        summary: 'Speak For Youth, Speak For Ourselves.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/JETPODS/2/4/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/4/cover/bg.png')
            ],
            tagText:'OVERVIEW'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/4/front/middle-en.png'),
              require('@/assets/images/three/JETPODS/2/4/front/top.png'),
              require('@/assets/images/three/JETPODS/2/4/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/4/base/bg-en.png'),
              require('@/assets/images/three/JETPODS/2/4/base/middle.png'),
              require('@/assets/images/three/JETPODS/2/4/base/top.png'),
            ],
          }
        ]
      },
      {
        title: 'GRAPHITE DARK',
        src: require('./../../assets/images/two/JETPODS/5.png'),
        footer: require('./../../assets/images/two/JETPODS/footer-en.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/5.png'),
        name: 'GRAPHITE DARK',
        summary: 'We Are Creating Our Own Future.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/JETPODS/2/5/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/5/cover/bg.png')
            ],
            tagText:'OVERVIEW'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/5/front/middle-en.png'),
              require('@/assets/images/three/JETPODS/2/5/front/top.png'),
              require('@/assets/images/three/JETPODS/2/5/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/5/base/bg-en.png'),
              require('@/assets/images/three/JETPODS/2/5/base/middle.png'),
            ],
          }
        ]
      }
    ],
    'M200': [
      {
        title: 'FIERY',
        src: require('./../../assets/images/two/M200/1.png'),
        footer: require('./../../assets/images/two/M200/footer-en.png'),
        cover: require('./../../assets/images/two/M200/cover/1.png'),
        name: 'FIERY',
        summary: 'Fireworks Symphony,this music of happiness!',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/M200/2/1/cover/pro.png'),
              require('@/assets/images/three/M200/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/M200/2/1/front/middle-en.png'),
              require('@/assets/images/three/M200/2/1/front/top.png'),
              require('@/assets/images/three/M200/2/1/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/M200/2/1/base/bg-en.png'),
              require('@/assets/images/three/M200/2/1/base/middle.png'),
              require('@/assets/images/three/M200/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'BLOONING',
        src: require('./../../assets/images/two/M200/2.png'),
        footer: require('./../../assets/images/two/M200/footer-en.png'),
        cover: require('./../../assets/images/two/M200/cover/2.png'),
        name: 'BLOONING',
        summary: 'Where flowers fall,eyes are full of dust and fragrance,listening to the wind sing.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/M200/2/2/cover/pro.png'),
              require('@/assets/images/three/M200/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/M200/2/2/front/middle-en.png'),
              require('@/assets/images/three/M200/2/2/front/top.png'),
              require('@/assets/images/three/M200/2/2/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/M200/2/2/base/bg-en.png'),
              require('@/assets/images/three/M200/2/2/base/middle.png'),
              require('@/assets/images/three/M200/2/2/base/top.png'),
            ],
          }
        ]
      },
      {
        title: 'CARMEN',
        src: require('./../../assets/images/two/M200/3.png'),
        footer: require('./../../assets/images/two/M200/footer-en.png'),
        cover: require('./../../assets/images/two/M200/cover/3.png'),
        name: 'CARMEN',
        summary: 'A pair of dust-free eyes reveals the gazing Carmen.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/M200/2/3/cover/pro.png'),
              require('@/assets/images/three/M200/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/M200/2/3/front/middle-en.png'),
              require('@/assets/images/three/M200/2/3/front/top.png'),
              require('@/assets/images/three/M200/2/3/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/M200/2/3/base/bg-en.png'),
              require('@/assets/images/three/M200/2/3/base/middle.png'),
              require('@/assets/images/three/M200/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: 'COAT OF ARMS',
        src: require('./../../assets/images/two/M200/4.png'),
        footer: require('./../../assets/images/two/M200/footer-en.png'),
        cover: require('./../../assets/images/two/M200/cover/4.png'),
        name: 'COAT OF ARMS',
        summary: 'A gluttonous feast.',
        items: [
          {
            title: 'COMBINATION',
            main: [
              require('@/assets/images/three/M200/2/4/cover/pro.png'),
              require('@/assets/images/three/M200/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: 'COVER',
            style: 1,
            main: [
              require('@/assets/images/three/M200/2/4/front/middle-en.png'),
              require('@/assets/images/three/M200/2/4/front/top.png'),
              require('@/assets/images/three/M200/2/4/front/bottom.png'),
            ]
          },{
            title: 'BACK',
            style: 1,
            main: [ 
              require('@/assets/images/three/M200/2/4/base/bg-en.png'),
              require('@/assets/images/three/M200/2/4/base/middle.png'),
              require('@/assets/images/three/M200/2/4/base/bottom.png'),
            ],
          }
        ]
      }
    ],
  },
  2: {
    'Q12': [
      {
        title: '黑白弦',
        src: require('./../../assets/images/two/Q12/2.png'),
        cover: require('./../../assets/images/two/Q12/cover/1.png'),
        footer: require('./../../assets/images/two/Q12/footer.png'),
        name: 'Black And White String',
        summary: 'Open Wearable Stereo.\n开放式可穿戴立体声耳机',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/Q12/2/2/cover/pro.png'),
              require('@/assets/images/three/Q12/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/2/front/middle.png'),
              require('@/assets/images/three/Q12/2/2/front/top.png'),
              require('@/assets/images/three/Q12/2/2/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/2/base/bg.png'),
              require('@/assets/images/three/Q12/2/2/base/middle.png'),
              require('@/assets/images/three/Q12/2/2/base/top.png'),
              require('@/assets/images/three/Q12/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '疾风银',
        src: require('./../../assets/images/two/Q12/3.png'),
        cover: require('./../../assets/images/two/Q12/cover/2.png'),
        footer: require('./../../assets/images/two/Q12/footer.png'),
        name: 'BLAST SILVER',
        summary: 'Open Wearable Stereo.\n开放式可穿戴立体声耳机',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/Q12/2/3/cover/pro.png'),
              require('@/assets/images/three/Q12/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/3/front/middle.png'),
              require('@/assets/images/three/Q12/2/3/front/top.png'),
              require('@/assets/images/three/Q12/2/3/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/3/base/bg.png'),
              require('@/assets/images/three/Q12/2/3/base/middle.png'),
              require('@/assets/images/three/Q12/2/3/base/top.png'),
              require('@/assets/images/three/Q12/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '萌芽绿',
        src: require('./../../assets/images/two/Q12/4.png'),
        cover: require('./../../assets/images/two/Q12/cover/3.png'),
        footer: require('./../../assets/images/two/Q12/footer.png'),
        name: 'SPROUTING GREEN',
        summary: 'Open Wearable Stereo.\n开放式可穿戴立体声耳机',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/Q12/2/4/cover/pro.png'),
              require('@/assets/images/three/Q12/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/4/front/middle.png'),
              require('@/assets/images/three/Q12/2/4/front/top.png'),
              require('@/assets/images/three/Q12/2/4/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/4/base/bg.png'),
              require('@/assets/images/three/Q12/2/4/base/middle.png'),
              require('@/assets/images/three/Q12/2/4/base/top.png'),
              require('@/assets/images/three/Q12/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '电音紫',
        src: require('./../../assets/images/two/Q12/1.png'),
        cover: require('./../../assets/images/two/Q12/cover/4.png'),
        footer: require('./../../assets/images/two/Q12/footer.png'),
        name: 'ELECTRIC PURPLE',
        summary: 'Open Wearable Stereo.\n开放式可穿戴立体声耳机',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/Q12/2/1/cover/pro.png'),
              require('@/assets/images/three/Q12/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/1/front/middle.png'),
              require('@/assets/images/three/Q12/2/1/front/top.png'),
              require('@/assets/images/three/Q12/2/1/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/1/base/bg.png'),
              require('@/assets/images/three/Q12/2/1/base/middle.png'),
              require('@/assets/images/three/Q12/2/1/base/top.png'),
              require('@/assets/images/three/Q12/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '水墨黑',
        src: require('./../../assets/images/two/Q12/5.png'),
        cover: require('./../../assets/images/two/Q12/cover/5.png'),
        footer: require('./../../assets/images/two/Q12/footer.png'),
        name: 'INK BLACK',
        summary: 'Open Wearable Stereo.\n开放式可穿戴立体声耳机',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/Q12/2/5/cover/pro.png'),
              require('@/assets/images/three/Q12/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/5/front/middle.png'),
              require('@/assets/images/three/Q12/2/5/front/top.png'),
              require('@/assets/images/three/Q12/2/5/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/5/base/bg.png'),
              require('@/assets/images/three/Q12/2/5/base/middle.png'),
              require('@/assets/images/three/Q12/2/5/base/top.png'),
              require('@/assets/images/three/Q12/2/5/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '跃动红',
        src: require('./../../assets/images/two/Q12/8.png'),
        cover: require('./../../assets/images/two/Q12/cover/6.png'),
        footer: require('./../../assets/images/two/Q12/footer.png'),
        name: 'VIBRANT MAGENTA',
        summary: 'Open Wearable Stereo.\n开放式可穿戴立体声耳机',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/Q12/2/8/cover/pro.png'),
              require('@/assets/images/three/Q12/2/8/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/8/front/middle.png'),
              require('@/assets/images/three/Q12/2/8/front/top.png'),
              require('@/assets/images/three/Q12/2/8/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/8/base/bg.png'),
              require('@/assets/images/three/Q12/2/8/base/middle.png'),
              require('@/assets/images/three/Q12/2/8/base/top.png'),
              require('@/assets/images/three/Q12/2/8/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '天空蓝',
        src: require('./../../assets/images/two/Q12/6.png'),
        cover: require('./../../assets/images/two/Q12/cover/7.png'),
        footer: require('./../../assets/images/two/Q12/footer.png'),
        name: 'SKY BLUE',
        summary: 'Open Wearable Stereo.\n开放式可穿戴立体声耳机',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/Q12/2/6/cover/pro.png'),
              require('@/assets/images/three/Q12/2/6/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/6/front/middle.png'),
              require('@/assets/images/three/Q12/2/6/front/top.png'),
              require('@/assets/images/three/Q12/2/6/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/6/base/bg.png'),
              require('@/assets/images/three/Q12/2/6/base/middle.png'),
              require('@/assets/images/three/Q12/2/6/base/top.png'),
              require('@/assets/images/three/Q12/2/6/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '行云白',
        src: require('./../../assets/images/two/Q12/7.png'),
        cover: require('./../../assets/images/two/Q12/cover/8.png'),
        footer: require('./../../assets/images/two/Q12/footer.png'),
        name: 'CLOUD WHITE',
        summary: 'Open Wearable Stereo.\n开放式可穿戴立体声耳机',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/Q12/2/7/cover/pro.png'),
              require('@/assets/images/three/Q12/2/7/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/Q12/2/7/front/middle.png'),
              require('@/assets/images/three/Q12/2/7/front/top.png'),
              require('@/assets/images/three/Q12/2/7/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/Q12/2/7/base/bg.png'),
              require('@/assets/images/three/Q12/2/7/base/middle.png'),
              require('@/assets/images/three/Q12/2/7/base/top.png'),
              require('@/assets/images/three/Q12/2/7/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'X12 PRO': [
      {
        title: '烈焰',
        src: require('./../../assets/images/two/X12PRO/10.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/10.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'FLAME',
        summary: 'While Enjoying Loneliness, You Can Also Burn Yourself.\n在享受孤独的同时，你也可以燃烧自己',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/10/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/10/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/10/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/10/front/top.png'),
              require('@/assets/images/three/X12PRO/2/10/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/10/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/10/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/10/base/top.png'),
              require('@/assets/images/three/X12PRO/2/10/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '幻',
        src: require('./../../assets/images/two/X12PRO/9.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/9.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'FANTASY',
        summary: 'New Peak Of Beauty All In Your Fantasy.\n在独自游走的城市里，音乐最先给了归属感',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/9/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/9/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/9/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/9/front/top.png'),
              require('@/assets/images/three/X12PRO/2/9/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/9/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/9/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/9/base/top.png'),
              require('@/assets/images/three/X12PRO/2/9/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '舞姬',
        src: require('./../../assets/images/two/X12PRO/8.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/8.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'DANCER',
        summary: 'Stay Up All Night Dancing With Your Ghost .\n每个人都是人生这场戏里拼命献艺的舞姬',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/8/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/8/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/8/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/8/front/top.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/8/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/8/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/8/base/top.png'),
              require('@/assets/images/three/X12PRO/2/8/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '怒放',
        src: require('./../../assets/images/two/X12PRO/7.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/7.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'BLOOMING',
        summary: 'New Peak Of Beauty Wander In The Blooming Kingdom.\n生来如此，注定不同',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/7/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/7/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/7/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/7/front/top.png'),
              require('@/assets/images/three/X12PRO/2/7/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/7/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/7/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/7/base/top.png'),
              require('@/assets/images/three/X12PRO/2/7/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '夜未央',
        src: require('./../../assets/images/two/X12PRO/6.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/6.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'HERE WITE YOU',
        summary: 'New Peak Of Beauty Bright Star Here With You.\n不想说的话，都关在单曲循环里',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/6/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/6/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/6/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/6/front/top.png'),
              require('@/assets/images/three/X12PRO/2/6/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/6/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/6/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/6/base/top.png'),
              require('@/assets/images/three/X12PRO/2/6/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '冰魄',
        src: require('./../../assets/images/two/X12PRO/5.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/5.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'ICE SOUL',
        summary: 'Cool As Ice Soul Calm Down Yourself.\n透明的冰，洁白的雪，感受洁净的声音',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/5/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/5/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/5/front/top.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/5/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/5/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/5/base/top.png'),
              require('@/assets/images/three/X12PRO/2/5/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '黑',
        src: require('./../../assets/images/two/X12PRO/4.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/4.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'BLACK',
        summary: 'Coming Music Bright Your Dark Night.\n黑夜天寒愁散玉，东皇海上星月明',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/4/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/4/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/4/front/top.png'),
              require('@/assets/images/three/X12PRO/2/4/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/4/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/4/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/4/base/top.png'),
              require('@/assets/images/three/X12PRO/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '白',
        src: require('./../../assets/images/two/X12PRO/3.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/3.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'WHITE',
        summary: 'Looking For Your Own Snow White.\n愿我如星君如月，夜夜流光相皎洁',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/3/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/3/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/3/front/top.png'),
              require('@/assets/images/three/X12PRO/2/3/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/3/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/3/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/3/base/top.png'),
              require('@/assets/images/three/X12PRO/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '粉',
        src: require('./../../assets/images/two/X12PRO/2.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/2.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'PINK',
        summary: "Pink powder arouses love and fills a girl's heart\n粉粉惹人爱，满满少女心",
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/2/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/2/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/2/front/top.png'),
              require('@/assets/images/three/X12PRO/2/2/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/2/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/2/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/2/base/top.png'),
              require('@/assets/images/three/X12PRO/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '绿',
        src: require('./../../assets/images/two/X12PRO/1.png'),
        cover: require('./../../assets/images/two/X12PRO/cover/1.png'),
        footer: require('./../../assets/images/two/X12PRO/footer.png'),
        name: 'GREEN',
        summary: 'Fresh Summer With Light Green.\n夏天气息，清新绿颜',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12PRO/2/1/cover/pro.png'),
              require('@/assets/images/three/X12PRO/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12PRO/2/1/front/middle.png'),
              require('@/assets/images/three/X12PRO/2/1/front/top.png'),
              require('@/assets/images/three/X12PRO/2/1/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12PRO/2/1/base/bg.png'),
              require('@/assets/images/three/X12PRO/2/1/base/middle.png'),
              require('@/assets/images/three/X12PRO/2/1/base/top.png'),
              require('@/assets/images/three/X12PRO/2/1/base/bottom.png'),
            ],
          }
        ]
      },
    ],
    'X12 ULTRA': [
      {
        title: '雪花白',
        src: require('./../../assets/images/two/X12ULTRA/1.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/1.png'),
        name: 'SNOW WHITE',
        summary: 'Start With Snow.\n赏心悦目，声动悦耳',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12ULTRA/2/1/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/1/front/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/1/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/1/front/bottom.png')
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/1/base/bg.png'),
              require('@/assets/images/three/X12ULTRA/2/1/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/1/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '绽颜石',
        src: require('./../../assets/images/two/X12ULTRA/4.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/4.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer.png'),
        name: 'YAN YANSHI',
        summary: 'Fragrance Comes From Cherry, Beauty Needs Your Care.\n虽如磐石，为你绽颜',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12ULTRA/2/4/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/4/front/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/4/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/4/front/bottom.png')
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/4/base/bg.png'),
              require('@/assets/images/three/X12ULTRA/2/4/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/4/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '星云石',
        src: require('./../../assets/images/two/X12ULTRA/5.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/5.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer.png'),
        name: 'STAR CLOUD',
        summary: 'Fresh Summer With Light Green.\n从遇见你开始，凛冬散尽星河长明',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12ULTRA/2/5/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/5/front/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/5/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/5/front/bottom.png')
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/5/base/bg.png'),
              require('@/assets/images/three/X12ULTRA/2/5/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/5/base/top.png'),
            ],
          }
        ]
      },
      {
        title: '伽蓝石',
        src: require('./../../assets/images/two/X12ULTRA/6.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/6.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer.png'),
        name: 'GALALINE',
        summary: 'Explore The Music Universe With Canaan Stone.\n渐渐之石，维其高矣',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12ULTRA/2/6/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/6/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/6/front/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/6/front/top.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/6/base/bg.png'),
              require('@/assets/images/three/X12ULTRA/2/6/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/6/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/6/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '进阶石',
        src: require('./../../assets/images/two/X12ULTRA/3.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/3.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer.png'),
        name: 'ADVANCED STONE',
        summary: 'Be With Nature, Proud Of Advance.\n破而后立，是为进阶',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12ULTRA/2/3/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/3/front/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/3/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/3/front/bottom.png')
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/3/base/bg.png'),
              require('@/assets/images/three/X12ULTRA/2/3/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/3/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '筑梦石',
        src: require('./../../assets/images/two/X12ULTRA/2.png'),
        cover: require('./../../assets/images/two/X12ULTRA/cover/2.png'),
        footer: require('./../../assets/images/two/X12ULTRA/footer.png'),
        name: 'DREAM STONE',
        summary: 'All Dreams Will Come True Once You Pursue.\n绿黑大理石成就空间的灵魂色彩',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/X12ULTRA/2/2/cover/pro.png'),
              require('@/assets/images/three/X12ULTRA/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/X12ULTRA/2/2/front/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/2/front/top.png'),
              require('@/assets/images/three/X12ULTRA/2/2/front/bottom.png')
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/X12ULTRA/2/2/base/bg.png'),
              require('@/assets/images/three/X12ULTRA/2/2/base/middle.png'),
              require('@/assets/images/three/X12ULTRA/2/2/base/top.png'),
              require('@/assets/images/three/X12ULTRA/2/2/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'E12 ULTRA': [
      {
        title: '筑梦石',
        src: require('./../../assets/images/two/E12ULTRA/10.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/10.png'),
        name: 'DREAM STONE',
        summary: 'All Dreams Will Come True Once You Pursue.\n绿黑大理石成就空间的灵魂色彩',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/10/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/10/cover/bg.png')
            ],
            tagText:'OVERVIEW'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/10/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/10/front/top.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/10/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/10/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/10/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/10/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '进阶石',
        src: require('./../../assets/images/two/E12ULTRA/9.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/9.png'),
        name: 'ADVANCED STONE',
        summary: 'Be With Nature, Proud Of Advance.\n破而后立，是为进阶',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/9/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/9/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/9/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/9/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/9/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/9/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/9/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/9/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/9/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '伽蓝石',
        src: require('./../../assets/images/two/E12ULTRA/8.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/8.png'),
        name: 'GALALINE',
        summary: 'Explore The Music Universe With Canaan Stone.\n渐渐之石，维其高矣',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/8/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/8/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/8/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/8/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/8/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/8/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/8/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/8/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/8/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '星云石',
        src: require('./../../assets/images/two/E12ULTRA/7.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/7.png'),
        name: 'STAR CLOUD',
        summary: 'Star Cloud Stone In Hand, The Music World Have It.\n从遇见你开始，凛冬散尽星河长明',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/7/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/7/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/7/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/7/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/7/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/7/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/7/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/7/base/top.png'),
            ],
          }
        ]
      },
      {
        title: '绽颜石',
        src: require('./../../assets/images/two/E12ULTRA/6.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/6.png'),
        name: 'YAN YANSHI',
        summary: 'Fragrance Comes From Cherry, Beauty Needs Your Care.\n虽如磐石，为你绽颜',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/6/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/6/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/6/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/6/front/top.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/6/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/6/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/6/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/6/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '闪耀黑金',
        src: require('./../../assets/images/two/E12ULTRA/5.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/5.png'),
        name: 'GLITTER DARK',
        summary: 'Flow The Glitter Music Into Your Living.\n将闪烁的音乐融入你的生活',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/5/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/5/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/5/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/5/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/5/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/5/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/5/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/5/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '霓虹砂砾',
        src: require('./../../assets/images/two/E12ULTRA/4.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/4.png'),
        name: 'NEON RED',
        summary: 'Enjoy The Music Beptism With The Neon Red.\n用霓虹红欣赏音乐魅力',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/4/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/4/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/4/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/4/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/4/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/4/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/4/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '蔚蓝星河',
        src: require('./../../assets/images/two/E12ULTRA/3.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/3.png'),
        name: 'GALAXY BLUE',
        summary: 'Dreamy Galaxy Where The Joy Begins.\n欢乐开始的梦幻银河',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/3/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/3/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/3/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/3/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/3/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/3/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/3/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '极光尘土',
        src: require('./../../assets/images/two/E12ULTRA/2.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/1.png'),
        name: 'AURORA GREEN',
        summary: 'Enjoy the music beptism with the aurora green.\n欣赏极光般的绿色音乐',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/2/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/2/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/2/front/top.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/2/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/2/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/2/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '雪花白',
        src: require('./../../assets/images/two/E12ULTRA/1.png'),
        footer: require('./../../assets/images/two/E12ULTRA/footer.png'),
        cover: require('./../../assets/images/two/E12ULTRA/cover/1.png'),
        name: 'SNOW WHITE',
        summary: 'Start With Snow.\n赏心悦目，声动悦耳',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E12ULTRA/2/1/cover/pro.png'),
              require('@/assets/images/three/E12ULTRA/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E12ULTRA/2/1/front/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/1/front/top.png'),
              require('@/assets/images/three/E12ULTRA/2/1/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E12ULTRA/2/1/base/bg.png'),
              require('@/assets/images/three/E12ULTRA/2/1/base/middle.png'),
              require('@/assets/images/three/E12ULTRA/2/1/base/top.png'),
              require('@/assets/images/three/E12ULTRA/2/1/base/bottom.png'),
            ],
          }
        ]
      },
    ],
    'G12 ELITE': [
      {
        title: '破',
        src: require('./../../assets/images/two/G12ELITE/1.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/1.png'),
        name: 'CHALLENGE',
        summary: 'Ingenuity Is The Voice Of The Creator With His Life.\n匠心是创造者用生命发出的声音',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/G12ELITE/2/1/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/1/front/middle.png'),
              require('@/assets/images/three/G12ELITE/2/1/front/top.png'),
              require('@/assets/images/three/G12ELITE/2/1/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/1/base/bg.png'),
              require('@/assets/images/three/G12ELITE/2/1/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/1/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '御',
        src: require('./../../assets/images/two/G12ELITE/2.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/2.png'),
        name: 'TRUTH',
        summary: 'We Given, We Striven, Turns Every Note Harmony Driven.\n我们发声，让每个音符成为我们自己',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/G12ELITE/2/2/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/2/front/middle.png'),
              require('@/assets/images/three/G12ELITE/2/2/front/top.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/2/base/bg.png'),
              require('@/assets/images/three/G12ELITE/2/2/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/2/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '凛',
        src: require('./../../assets/images/two/G12ELITE/3.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/3.png'),
        name: 'UNITY',
        summary: 'Sabbat! Hearing The Voice Of The Universe.\n无魔宴，不青春',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/G12ELITE/2/3/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/3/front/middle.png'),
              require('@/assets/images/three/G12ELITE/2/3/front/top.png'),
              require('@/assets/images/three/G12ELITE/2/3/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/3/base/bg.png'),
              require('@/assets/images/three/G12ELITE/2/3/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/3/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '幽',
        src: require('./../../assets/images/two/G12ELITE/4.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/4.png'),
        name: 'DEFEND',
        summary: 'Dare To Release, Dare To Strive.\n敢释放，敢争取',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/G12ELITE/2/4/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/4/front/middle.png'),
              require('@/assets/images/three/G12ELITE/2/4/front/top.png'),
              require('@/assets/images/three/G12ELITE/2/4/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/4/base/bg.png'),
              require('@/assets/images/three/G12ELITE/2/4/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/4/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '影',
        src: require('./../../assets/images/two/G12ELITE/5.png'),
        footer: require('./../../assets/images/two/G12ELITE/footer.png'),
        cover: require('./../../assets/images/two/G12ELITE/cover/5.png'),
        name: 'HARMONY',
        summary: 'Fight For Youth, Fight For Dreams.\n为青春而战，为梦想而战',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/G12ELITE/2/5/cover/pro.png'),
              require('@/assets/images/three/G12ELITE/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/G12ELITE/2/5/front/middle.png'),
              require('@/assets/images/three/G12ELITE/2/5/front/top.png'),
              require('@/assets/images/three/G12ELITE/2/5/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/G12ELITE/2/5/base/bg.png'),
              require('@/assets/images/three/G12ELITE/2/5/base/middle.png'),
              require('@/assets/images/three/G12ELITE/2/5/base/top.png'),
              require('@/assets/images/three/G12ELITE/2/5/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'E16': [
      {
        title: '霓虹',
        src: require('./../../assets/images/two/E16/1.png'),
        footer: require('./../../assets/images/two/E16/footer.png'),
        cover: require('./../../assets/images/two/E16/cover/1.png'),
        name: 'NEON',
        summary: 'Dare To Release, Dare To Strive.\n敢于释放，敢于奋斗',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E16/2/1/cover/pro.png'),
              require('@/assets/images/three/E16/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E16/2/1/front/middle.png'),
              require('@/assets/images/three/E16/2/1/front/top.png'),
              require('@/assets/images/three/E16/2/1/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E16/2/1/base/bg.png'),
              require('@/assets/images/three/E16/2/1/base/middle.png'),
              require('@/assets/images/three/E16/2/1/base/top.png'),
              require('@/assets/images/three/E16/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '极光',
        src: require('./../../assets/images/two/E16/2.png'),
        footer: require('./../../assets/images/two/E16/footer.png'),
        cover: require('./../../assets/images/two/E16/cover/2.png'),
        name: 'AURORA',
        summary: 'Enjoy The Music Beptism With The Aurora Green\n神秘之声 极光尘土',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E16/2/2/cover/pro.png'),
              require('@/assets/images/three/E16/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E16/2/2/front/middle.png'),
              require('@/assets/images/three/E16/2/2/front/top.png'),
              require('@/assets/images/three/E16/2/2/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E16/2/2/base/bg.png'),
              require('@/assets/images/three/E16/2/2/base/middle.png'),
              require('@/assets/images/three/E16/2/2/base/top.png'),
              require('@/assets/images/three/E16/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '黑金',
        src: require('./../../assets/images/two/E16/3.png'),
        footer: require('./../../assets/images/two/E16/footer.png'),
        cover: require('./../../assets/images/two/E16/cover/3.png'),
        name: 'MYSTIC',
        summary: 'Fight For Youth, Fight For Dreams.\n为青春而战，为梦想而战',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E16/2/3/cover/pro.png'),
              require('@/assets/images/three/E16/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E16/2/3/front/middle.png'),
              require('@/assets/images/three/E16/2/3/front/top.png'),
              require('@/assets/images/three/E16/2/3/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E16/2/3/base/bg.png'),
              require('@/assets/images/three/E16/2/3/base/middle.png'),
              require('@/assets/images/three/E16/2/3/base/top.png'),
              require('@/assets/images/three/E16/2/3/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'E18': [
      {
        title: '月光银',
        src: require('./../../assets/images/two/E18/1.png'),
        footer: require('./../../assets/images/two/E18/footer.png'),
        cover: require('./../../assets/images/two/E18/cover/1.png'),
        name: 'MOONLIGHT SILVER',
        summary: 'Dare To Live, Dare To Awake.\n敢于生活，敢于觉醒',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E18/2/1/cover/pro.png'),
              require('@/assets/images/three/E18/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/1/front/middle.png'),
              require('@/assets/images/three/E18/2/1/front/top.png'),
              require('@/assets/images/three/E18/2/1/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/1/base/bg.png'),
              require('@/assets/images/three/E18/2/1/base/middle.png'),
              require('@/assets/images/three/E18/2/1/base/top.png'),
              require('@/assets/images/three/E18/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '玄墨黑',
        src: require('./../../assets/images/two/E18/2.png'),
        footer: require('./../../assets/images/two/E18/footer.png'),
        cover: require('./../../assets/images/two/E18/cover/2.png'),
        name: 'DARK BLACK',
        summary: 'Within My Music, My Soul Flames.\n在我的音乐里，我的灵魂燃烧',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E18/2/2/cover/pro.png'),
              require('@/assets/images/three/E18/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/2/front/middle.png'),
              require('@/assets/images/three/E18/2/2/front/top.png'),
              require('@/assets/images/three/E18/2/2/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/2/base/bg.png'),
              require('@/assets/images/three/E18/2/2/base/middle.png'),
              require('@/assets/images/three/E18/2/2/base/top.png'),
              require('@/assets/images/three/E18/2/2/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '荧惑红',
        src: require('./../../assets/images/two/E18/3.png'),
        footer: require('./../../assets/images/two/E18/footer.png'),
        cover: require('./../../assets/images/two/E18/cover/3.png'),
        name: 'FLUORESCEIN RED',
        summary: 'Superior Audio Bestowed By Awesome Tune.\n卓越的音质，美妙的曲调',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E18/2/3/cover/pro.png'),
              require('@/assets/images/three/E18/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/3/front/middle.png'),
              require('@/assets/images/three/E18/2/3/front/top.png'),
              require('@/assets/images/three/E18/2/3/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/3/base/bg.png'),
              require('@/assets/images/three/E18/2/3/base/middle.png'),
              require('@/assets/images/three/E18/2/3/base/top.png'),
              require('@/assets/images/three/E18/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '极光绿',
        src: require('./../../assets/images/two/E18/4.png'),
        footer: require('./../../assets/images/two/E18/footer.png'),
        cover: require('./../../assets/images/two/E18/cover/4.png'),
        name: 'AURORA GREEN',
        summary: 'We Could Be The Whole World.\n我们，可以是一个世界',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E18/2/4/cover/pro.png'),
              require('@/assets/images/three/E18/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/4/front/middle.png'),
              require('@/assets/images/three/E18/2/4/front/top.png'),
              require('@/assets/images/three/E18/2/4/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/4/base/bg.png'),
              require('@/assets/images/three/E18/2/4/base/middle.png'),
              require('@/assets/images/three/E18/2/4/base/top.png'),
              require('@/assets/images/three/E18/2/4/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '灵幽蓝',
        src: require('./../../assets/images/two/E18/5.png'),
        footer: require('./../../assets/images/two/E18/footer.png'),
        cover: require('./../../assets/images/two/E18/cover/5.png'),
        name: 'SPIRIT BLUE',
        summary: 'Ingenuity Is The Voice Of The Creator With His Life.\n独创是造物主用生命发出的声音',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/E18/2/5/cover/pro.png'),
              require('@/assets/images/three/E18/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/E18/2/5/front/middle.png'),
              require('@/assets/images/three/E18/2/5/front/top.png'),
              require('@/assets/images/three/E18/2/5/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/E18/2/5/base/bg.png'),
              require('@/assets/images/three/E18/2/5/base/middle.png'),
              require('@/assets/images/three/E18/2/5/base/top.png'),
              require('@/assets/images/three/E18/2/5/base/bottom.png'),
            ],
          }
        ]
      }
    ],
    'JETPODS': [
      {
        title: '象牙白',
        src: require('./../../assets/images/two/JETPODS/1.png'),
        footer: require('./../../assets/images/two/JETPODS/footer.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/1.png'),
        name: 'IVORY WHITE',
        summary: 'Superior Audio Bestowed By Awesome Tune.\n卓越的音质，美妙的曲调',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/JETPODS/2/1/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/1/front/middle.png'),
              require('@/assets/images/three/JETPODS/2/1/front/top.png'),
              require('@/assets/images/three/JETPODS/2/1/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/1/base/bg.png'),
              require('@/assets/images/three/JETPODS/2/1/base/middle.png'),
              require('@/assets/images/three/JETPODS/2/1/base/top.png'),
            ],
          }
        ]
      },
      {
        title: '竹青绿',
        src: require('./../../assets/images/two/JETPODS/2.png'),
        footer: require('./../../assets/images/two/JETPODS/footer.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/2.png'),
        name: 'BAMBOO GREEN',
        summary: 'Fight For Youth, Fight For Dreams.\n为青春而战，为梦想而战',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/JETPODS/2/2/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/2/front/middle.png'),
              require('@/assets/images/three/JETPODS/2/2/front/top.png'),
              require('@/assets/images/three/JETPODS/2/2/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/2/base/bg.png'),
              require('@/assets/images/three/JETPODS/2/2/base/middle.png'),
              require('@/assets/images/three/JETPODS/2/2/base/top.png'),
            ],
          }
        ]
      },
      {
        title: '珊瑚红',
        src: require('./../../assets/images/two/JETPODS/3.png'),
        footer: require('./../../assets/images/two/JETPODS/footer.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/3.png'),
        name: 'CORAL RED',
        summary: 'We Could Be The Whole World.\n我们可以成为整个世界',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/JETPODS/2/3/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/3/front/middle.png'),
              require('@/assets/images/three/JETPODS/2/3/front/top.png'),
              require('@/assets/images/three/JETPODS/2/3/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/3/base/bg.png'),
              require('@/assets/images/three/JETPODS/2/3/base/middle.png'),
              require('@/assets/images/three/JETPODS/2/3/base/top.png'),
            ],
          }
        ]
      },
      {
        title: '深海蓝',
        src: require('./../../assets/images/two/JETPODS/4.png'),
        footer: require('./../../assets/images/two/JETPODS/footer.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/4.png'),
        name: 'OCEAN BLUE',
        summary: 'Speak For Youth, Speak For Ourselves.\n为青春说话，为我们自己说话',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/JETPODS/2/4/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/4/front/middle.png'),
              require('@/assets/images/three/JETPODS/2/4/front/top.png'),
              require('@/assets/images/three/JETPODS/2/4/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/4/base/bg.png'),
              require('@/assets/images/three/JETPODS/2/4/base/middle.png'),
              require('@/assets/images/three/JETPODS/2/4/base/top.png'),
            ],
          }
        ]
      },
      {
        title: '石墨黑',
        src: require('./../../assets/images/two/JETPODS/5.png'),
        footer: require('./../../assets/images/two/JETPODS/footer.png'),
        cover: require('./../../assets/images/two/JETPODS/cover/5.png'),
        name: 'GRAPHITE DARK',
        summary: 'We Are Creating Our Own Future.\n我们正在创造我们自己的未来',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/JETPODS/2/5/cover/pro.png'),
              require('@/assets/images/three/JETPODS/2/5/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/JETPODS/2/5/front/middle.png'),
              require('@/assets/images/three/JETPODS/2/5/front/top.png'),
              require('@/assets/images/three/JETPODS/2/5/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/JETPODS/2/5/base/bg.png'),
              require('@/assets/images/three/JETPODS/2/5/base/middle.png'),
            ],
          }
        ]
      }
    ],
    'M200': [
      {
        title: '炽热',
        src: require('./../../assets/images/two/M200/1.png'),
        footer: require('./../../assets/images/two/M200/footer.png'),
        cover: require('./../../assets/images/two/M200/cover/1.png'),
        name: 'FIERY',
        summary: 'Fireworks Symphony,this music of happiness!\n焰火交响曲幸福的乐曲！',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/M200/2/1/cover/pro.png'),
              require('@/assets/images/three/M200/2/1/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/M200/2/1/front/middle.png'),
              require('@/assets/images/three/M200/2/1/front/top.png'),
              require('@/assets/images/three/M200/2/1/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/M200/2/1/base/bg.png'),
              require('@/assets/images/three/M200/2/1/base/middle.png'),
              require('@/assets/images/three/M200/2/1/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '繁花',
        src: require('./../../assets/images/two/M200/2.png'),
        footer: require('./../../assets/images/two/M200/footer.png'),
        cover: require('./../../assets/images/two/M200/cover/2.png'),
        name: 'BLOONING',
        summary: 'Where flowers fall,eyes are full of dust and fragrance,listening to the wind sing.\n繁花落处，满目尘香听风吟',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/M200/2/2/cover/pro.png'),
              require('@/assets/images/three/M200/2/2/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/M200/2/2/front/middle.png'),
              require('@/assets/images/three/M200/2/2/front/top.png'),
              require('@/assets/images/three/M200/2/2/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/M200/2/2/base/bg.png'),
              require('@/assets/images/three/M200/2/2/base/middle.png'),
              require('@/assets/images/three/M200/2/2/base/top.png'),
            ],
          }
        ]
      },
      {
        title: '卡门',
        src: require('./../../assets/images/two/M200/3.png'),
        footer: require('./../../assets/images/two/M200/footer.png'),
        cover: require('./../../assets/images/two/M200/cover/3.png'),
        name: 'CARMEN',
        summary: 'A pair of dust-free eyes reveals the gazing Carmen.\n一双没有灰尘的眼睛，流出凝望中的卡门',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/M200/2/3/cover/pro.png'),
              require('@/assets/images/three/M200/2/3/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/M200/2/3/front/middle.png'),
              require('@/assets/images/three/M200/2/3/front/top.png'),
              require('@/assets/images/three/M200/2/3/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/M200/2/3/base/bg.png'),
              require('@/assets/images/three/M200/2/3/base/middle.png'),
              require('@/assets/images/three/M200/2/3/base/bottom.png'),
            ],
          }
        ]
      },
      {
        title: '纹章',
        src: require('./../../assets/images/two/M200/4.png'),
        footer: require('./../../assets/images/two/M200/footer.png'),
        cover: require('./../../assets/images/two/M200/cover/4.png'),
        name: 'COAT OF ARMS',
        summary: 'A gluttonous feast.\n饕餮盛宴',
        items: [
          {
            title: '组合',
            main: [
              require('@/assets/images/three/M200/2/4/cover/pro.png'),
              require('@/assets/images/three/M200/2/4/cover/bg.png')
            ],
            tagText:'COMBINATION'
          },
          {
            title: '正面',
            style: 1,
            main: [
              require('@/assets/images/three/M200/2/4/front/middle.png'),
              require('@/assets/images/three/M200/2/4/front/top.png'),
              require('@/assets/images/three/M200/2/4/front/bottom.png'),
            ]
          },{
            title: '背面',
            style: 1,
            main: [ 
              require('@/assets/images/three/M200/2/4/base/bg.png'),
              require('@/assets/images/three/M200/2/4/base/middle.png'),
              require('@/assets/images/three/M200/2/4/base/bottom.png'),
            ],
          }
        ]
      }
    ],
  }
}