import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3bb21fa1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "com-swiperThree-btns-div"
};
const _hoisted_2 = {
  class: "com-swiperThree-bottom-text-l"
};
const _hoisted_3 = {
  class: "com-swiperThree-bottom-text-r"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "com-swiperThree-top-div"
};
const _hoisted_6 = {
  class: "com-swiperThree-menu-div"
};
const _hoisted_7 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  return _openBlock(), _createElementBlock("div", {
    class: "com-swiperThree-box",
    style: _normalizeStyle({
      height: `${$props.itemHeight}px`
    })
  }, [$props.dataArr && $props.dataArr.length ? (_openBlock(), _createBlock(_component_swiper, {
    key: 0,
    class: "com-swiperThree-swiper",
    direction: "horizontal",
    height: $props.itemHeight,
    onSwiper: $options.onSwiper,
    onSlideChange: $options.onSlideChange,
    speed: 1500
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dataArr, (item, index) => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        key: index
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
          item: item,
          fullName: `${$props.products.name}-${item.name}`,
          text: $props.products.text,
          index: index
        }, undefined, true)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 3
  }, 8, ["height", "onSwiper", "onSlideChange"])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["com-swiperThree-bottom-text", {
      // 'com-swiperThree-bottom-text-preHover': isPreHover,
      // 'com-swiperThree-bottom-text-nextHover': isNextHover,
      'com-swiperThree-bottom-text-activeNext': $data.isNextClick,
      'com-swiperThree-bottom-text-activePre': $data.isPreClick
    }])
  }, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.products.name) + "-" + _toDisplayString($props.dataArr[$data.active].name), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    class: "com-swiperThree-bottom-text-r-img",
    alt: "",
    src: $props.dataArr[$data.active].footer
  }, null, 8, _hoisted_4)])], 2), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: _normalizeClass(["com-swiperThree-btn-div", {
      'com-swiperThree-pre-div': !$options.isFirst
    }]),
    onMouseenter: _cache[0] || (_cache[0] = $event => $options.addBtnMoseEnter(1, $options.isFirst)),
    onMouseleave: _cache[1] || (_cache[1] = $event => $options.addBtnMoseLeave(1, $options.isFirst)),
    onClick: _cache[2] || (_cache[2] = (...args) => $options.toSlidePrev && $options.toSlidePrev(...args))
  }, [_createElementVNode("i", {
    class: _normalizeClass(["iconfont icon-xiayige-copy com-swiperThree-btn-icon", {
      'com-swiperThree-btn-icon-noClick': $options.isFirst
    }])
  }, null, 2)], 34), _createElementVNode("div", {
    class: _normalizeClass(["com-swiperThree-btn-div", {
      'com-swiperThree-next-div': !$options.isLast
    }]),
    onMouseenter: _cache[3] || (_cache[3] = $event => $options.addBtnMoseEnter(2, $options.isLast)),
    onMouseleave: _cache[4] || (_cache[4] = $event => $options.addBtnMoseLeave(2, $options.isLast)),
    onClick: _cache[5] || (_cache[5] = $event => $options.toSlideNext())
  }, [_createElementVNode("i", {
    class: _normalizeClass(["iconfont icon-xiayige com-swiperThree-btn-icon", {
      'com-swiperThree-btn-icon-noClick': $options.isLast
    }])
  }, null, 2)], 34)])], 512), [[_vShow, $props.hasBtn]]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dataArr, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["com-swiperThree-menu-item-div", {
        'com-swiperThree-menu-item-div-active': $data.active == index
      }]),
      key: index,
      onClick: $event => $options.slideTo(index)
    }, _toDisplayString(item.title), 11, _hoisted_7);
  }), 128))])], 4);
}